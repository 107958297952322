import React from "react";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/material";

export default function CardPortfolio({ title, image }) {

  return (
    <>
      <Box
        sx={{
          maxHeight: { xs: "70vh", md: "50vh" },
          maxWidth: { xs: "55vw", sm: "40vw", md: "40vw", lg: "45vw" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pb: { xs: 4 },
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={title}
          sx={{
            height: "100%",
            objectFit: "fill",
            borderRadius: 1,
            p:0,
            m:0,
          }}
          loading="lazy"
        />
      </Box>
    </>
  );
}


