import React from "react";
import { Grid, Typography, Link, Box } from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { strings } from "../../core/utils/strings";
import { useSwiperContext } from "../../context/SwiperPage.context";

const FooterSection = () => {
  const { swiperInstance } = useSwiperContext();

  const navigateToSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideTo(0);
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        color: "white",
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={4} sm={6} md={4}>
          <Logo handleClick={navigateToSlide} />
        </Grid>
        <Grid
          item
          xs={8}
          sm={6}
          md={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "flex-end",
          }}
        >
          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <Typography
                variant="p"
                color="inherit"
                sx={{ fontSize: { xs: 12, sm: 16 }, fontFamily: "Raleway" }}
              >
                {strings.FOOTER.FOLLOW_US}
              </Typography>
              <Link
                href="https://www.facebook.com/Togetherdevs/"
                color="inherit"
                sx={{ ml: 1 }}
                aria-label="Visita nuestra página en Facebook"
              >
                <Facebook />
              </Link>
              <Link
                href="https://www.linkedin.com/company/togetherdevs/about/"
                color="inherit"
                sx={{ ml: 1 }}
                aria-label="Visita nuestra página en LinkedIn"
              >
                <LinkedIn />
              </Link>
            </Box>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            <Typography
              variant="body2"
              color="inherit"
              sx={{ mt: { xs: 1, sm: 1 }, fontFamily: "Raleway" }}
            >
              {strings.FOOTER.ADDRESS}
            </Typography>
            <Typography
              variant="body2"
              color="inherit"
              sx={{
                mt: { xs: 0, sm: 1 },
                fontFamily: "Raleway",
                fontSize: { xs: 10, sm: 14 },
              }}
            >
              {strings.FOOTER.ALL_RIGHTS_RESERVED}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Logo = ({ handleClick }) => (
  <Box
    p={{ xs: 0, sm: 4, md: 3 }}
    display="flex"
    justifyContent="center"
    alignItems="center"
    onClick={handleClick}
    sx={{
      cursor: "pointer",
      maxWidth: { xs: "50vw", sm: "auto", md: "8vw" },
    }}
  >
    <img
  src={"/logos/TogetherDevs.svg"}
  height="40px"
  width="100%"
  alt="TogetherDevs"
   loading="lazy"
/>
  </Box>
);

export default FooterSection;
