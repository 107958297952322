import { useRoutes } from "react-router-dom";
import { Suspense } from "react";
import SuspenseLoader from "./core/utils/SuspenseLoader";
import router from "./router";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { ActiveSectionProvider } from "./context/ActiveSection.context";
import { SwiperProvider } from "./context/SwiperPage.context";
import Seo from "./core/seo";

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SwiperProvider>
        <ActiveSectionProvider>
          <Seo
            title="Together Devs: Experts in Collaborative Software Development"
            description="At Together Devs, we unite global talent to create innovative software solutions. Specialists in collaborative development and cutting-edge technology to take your business to the next level."
            keywords="collaborative software development, technological solutions, remote teamwork, technological innovation, programming services"
            author="Together Devs Team"
            canonicalUrl="https://www.togetherdevs.com/"
          />
          <Suspense fallback={<SuspenseLoader />}>{content}</Suspense>
        </ActiveSectionProvider>
      </SwiperProvider>
    </ThemeProvider>
  );
}

export default App;
