import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSwiperContext } from "../../context/SwiperPage.context";

const InitialSection = ({ title, buttonConfig, background, description }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { swiperInstance } = useSwiperContext();

  const navigateToSlide = () => {
    if (swiperInstance) {
      swiperInstance.slideTo(matches ? 7 : 13); // if add section change
    }
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        color: "#fff",
        "@media (max-width: 768px)": {
          padding: "0.5rem",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.grey,
          opacity: 0.8,
          padding: "1rem",
          borderRadius: "0 3rem 3rem 0",
          textAlign: "left",
          marginRight: "2rem",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize:"3.5rem",
            "@media (max-width: 768px)": {
              fontSize: "1.5rem",
            },
          }}
        >
          {title.toUpperCase()}
        </Typography>
        <Typography
          variant="h5"
          component="p"
          gutterBottom
          fontFamily={"Raleway"}     
          >{description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          width: "100%",
          position: "relative",
          bottom: "-10%",
          left: { xs: "0", sm: "5%" },
        }}
      >
        <Button
          variant="contained"
          sx={{
            padding: "1rem 2rem",
            "@media (max-width: 768px)": {
              padding: "0.5rem 1rem",
              fontSize: "0.875rem",
            },
          }}
          onClick={navigateToSlide}
        >
          {buttonConfig.text}
        </Button>
      </Box>
    </Box>
  );
};

export default InitialSection;