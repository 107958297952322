import React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";

export default function CardTestimonial({
  name,
  description,
  avatar,
  avatarAlignment,
}) {
  const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

  const direct = avatarAlignment === "left" ? "row" : "row-reverse";
  const justify = avatarAlignment === "left" ? "flex-start" : "flex-end";
  const paddingx =
    avatarAlignment === "left" ? "4rem 2rem 4rem 6rem" : "1rem 12rem 1rem rem";
  const pos =
    avatarAlignment === "left" ? "translate(-5%, 0%)" : "translate(5%, 0%)";
  const position =
    avatarAlignment === "left" ? "translate(30%, 0%)" : "translate(-30%, 0%)";

   const positionMobile = "translate(0%, 25%)";
   const paddingxMobile = "2.5rem 1rem 1.5rem 1rem"

  return (
    <Stack
      container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: direct },
        alignItems: "center",
        justifyContent: "center",
        textAlign: { xs: "center", sm: justify },
        height: "100%",
        p: "0",
        maxWidth: "70%",
      }}
    >
      <Avatar
        alt={name}
        src={avatar}
        sizes="large"
        sx={{
          width: 150,
          height: 150,
          objectFit: "cover",
          transform: matches ? position : positionMobile,
          zIndex: 1,
        }}
        loading="lazy"
      />
      <Paper
        elevation={5}
        className="card"
        sx={{
          backgroundColor: theme.palette.primary.grey,
          opacity: 0.7,
          borderRadius: "25px",
          zIndex: 0,
          maxWidth:matches ? "60%" :"80%",
          marginBottom: "1rem",
          padding: matches ? paddingx : paddingxMobile,
          transform: matches ? pos : "translate(0%, 0%)",
          justifyContent: justify,
          textAlign: avatarAlignment === "left" ? "left" : "right",
        }}
      >
        <Typography
          fontSize={16}
          component="div"
          fontFamily={"Raleway"}
          color={theme.palette.primary.black}
          sx={{ fontWeight: 700 }}
        >
          {name}
        </Typography>

        <Typography
          fontSize={14}
          component="div"
          fontFamily={"Raleway"}
          color={theme.palette.common.white}
          sx={{ fontWeight: 500, whiteSpace: "normal" }}
        >
          {description}
        </Typography>
      </Paper>
    </Stack>
  );
}
