import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Grid, useTheme, useMediaQuery } from "@mui/material";


export default function CardBio({ name, rol, description, avatar }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      direction={matches ? "row" : "column"}
      alignItems="center"
      position="relative"
      sx={{ background: theme.palette.primary.grey }}
    >
      <Stack direction="column" spacing={4} alignItems="center">
        <Stack item xs={6} sm={6} sx={{ position: "relative" }}>
          <Typography
            fontSize={matches ? 14 : 8}
            component="div"
            fontFamily="Raleway"
            color={theme.palette.primary.white} // letra
            align="left"
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              backgroundColor: theme.palette.primary.black, //fondo
              opacity: 0.65,
              marginTop: matches ? "2rem" : "3rem",
              marginLeft: matches ? "1rem" : "0",
              padding: matches
                ? "10px 50px 10px 150px"
                : "0.5rem 3rem 0.5rem 3rem",
              borderRadius: " 0 25px 25px 0",
              zIndex: 0,
              maxWidth: "90%",
              whiteSpace: "nowrap",
            }}
          >
            {name}
            <br />
            {rol}
          </Typography>

          <Avatar
            alt={name}
            src={avatar}
            sx={{
              width: matches ? 90 : 50,
              height: matches ? 90 : 50,
              zIndex: 1,
              position: "absolute",
              marginTop: matches ? "4rem" : "2rem",
              right: matches ? "75%" : "35%",
              transform: "translate(-50%, -50%)",
            }}
            loading="lazy"
          />
        </Stack>
        <Grid item container alignItems="start" direction="column" spacing={1}>
          <Grid item>
            <Typography
              fontSize={matches ? 14 : 10}
              component="div"
              fontFamily="Raleway"
              color={theme.palette.primary.white}
              sx={{
                fontWeight: 500,
                mb: matches ? "1rem" : "1rem",
                ml: matches ? "2rem" : "2rem",
                maxWidth: matches ? "90%" : "70%",
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
