import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";

export default function CardOurLeaders({
  name,
  rol,
  description,
  avatar,
  avatarAlignment,
}) {
  const theme = useTheme();
      const matches = useMediaQuery(theme.breakpoints.up("md"));

  const direct = avatarAlignment === "left" ? "row" : "row-reverse";
  const position = avatarAlignment === "left" ? "translate(20%, 0%)" : "translate(-20%, 0%)";
  const justify = avatarAlignment === "left" ? "left" : "right";
  const paddingx =
    avatarAlignment === "left" ? "1rem 5rem 1rem 5rem" : "1rem 5rem 1rem 5rem"; 
  const pos = avatarAlignment === "left" ? "translate(-5%, 0%)" : "translate(5%, 0%)";

  return (
    <Stack
      container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: direct },
        alignItems: "center",
        justifyContent: "center",
        textAlign: { xs: "center", sm: justify },
        height: "100%",
        p: 0,
        mx: 5,
        mb: 2,
      }}
    >
      <Avatar
        alt={name}
        src={avatar}
        sx={{
          width: { xs: 100, sm: 140 },
          height: { xs: 100, sm: 140 },
          zIndex: 1,
          transform: { xs: "translate(0%, 12%)", sm: position },
          p: 0,
          m: 0,
          mb: { xs: 0, sm: 0 },
        }}
        loading="lazy"
      />
      <Stack
        sx={{
          backgroundColor: theme.palette.primary.grey,
          opacity: 0.7,
          borderRadius: "25px",
          zIndex: 0,
          minWidth: { xs: "90%", sm: "70%" },
          maxWidth: { xs: "120%", sm: "70%" },

          whiteSpace: "normal",
          padding: { xs: "0.8rem", sm: paddingx },
          transform: { sm: pos },
        }}
      >
        <Typography
          fontSize={matches ? 18 : 14}
          component="h3"
          fontFamily="Raleway"
          color={theme.palette.common.white}
          align={justify}
          sx={{ fontWeight: 700, textTransform: "uppercase" }}
        >
          {name}
          <br />
          {rol}
        </Typography>
        <Typography
          fontSize={matches ? 14 : 12}
          component="p"
          fontFamily="Raleway"
          color={theme.palette.common.white}
          align={justify}
          sx={{
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
}
