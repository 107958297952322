import React, { useEffect } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import NavBar from "../../components/NavBar";
import { useActiveSection } from "../../context/ActiveSection.context";
import { useSwiperContext } from "../../context/SwiperPage.context";
import { sectionsDesktop, sectionsMobile } from "../../core/utils/contents";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

const OurTeam = () => {
  const { swiperInstance, setSwiperInstance } = useSwiperContext();
  const { setActiveSection } = useActiveSection();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const setSection = matches ? sectionsDesktop : sectionsMobile;

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const index = setSection.findIndex((Section) => Section.id === hash);
    if (index !== -1 && swiperInstance) {
      swiperInstance.slideTo(index);
    }
  }, [swiperInstance, setSection]);

  useEffect(() => {
    if (swiperInstance) {
      let timeout = setTimeout(() => {
        swiperInstance.slideTo(matches ? 1 : 3);

      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [swiperInstance, matches]);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.on("slideChange", () => {
        setActiveSection(swiperInstance.activeIndex);
      });
    }
  }, [swiperInstance, setActiveSection]);

  return (
    <>
      <NavBarWrapper />
      <CustomSwiper
        setSection={setSection}
        setSwiperInstance={setSwiperInstance}
      />
    </>
  );
};

const NavBarWrapper = () => (
  <Box component="nav">
    <Container maxWidth={false} disableGutters>
      <NavBar />
    </Container>
  </Box>
);

const CustomSwiper = ({ setSection, setSwiperInstance }) => {
  return (
    <Swiper
      onSwiper={(swiper) => setSwiperInstance(swiper)}
      direction="vertical"
      slidesPerView={1}
     mousewheel
     pagination={{ clickable: true, bullet: false }}
    modules={[Mousewheel]}
      className="swiperPage"
    >
      {setSection.map((SectionComponent, index) => (
        <SwiperSlide key={index}>
          <SectionComponent />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default OurTeam;
