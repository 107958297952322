import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { strings } from "../../../core/utils/strings";
import CardService from "../../../components/Cards/CardService";
import { gradients } from "../../../theme/gradients";

import "./styles.css";

const ServicesSection = () => {
  const slideConfigs = Object.values(strings.SERVICES);
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box>
        <Typography
          variant="h2"
          component="h2"
          fontFamily={"Raleway"}
          gutterBottom
          sx={{
            pt: 8,
            color: theme.palette.primary.white,
            fontWeight: 700,
          }}
        >
          {strings.SERVICES_TITLE}
        </Typography>
        <Box
          sx={{
            width: "20%",
            height: "8px",
            backgroundColor: theme.palette.primary.main,
            marginBottom: "3rem",
          }}
        />
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {slideConfigs.map((service, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <CardService
              title={service.TITLE}
              description={service.DESCRIPTION}
              image={service.IMAGE}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ServicesSection;


