import React from 'react';

const SwiperContext = React.createContext(null);

export const useSwiperContext = () => React.useContext(SwiperContext);

export const SwiperProvider = ({ children }) => {
  const [swiperInstance, setSwiperInstance] = React.useState(null);

  return (
    <SwiperContext.Provider value={{ swiperInstance, setSwiperInstance }}>
      {children}
    </SwiperContext.Provider>
  );
};
