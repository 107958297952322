import { Grid, useTheme } from "@mui/material";
import React from "react";

import { strings } from "../../../../core/utils/strings";

import CardBio from "../../../../components/Cards/CardBio.jsx";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import { gradients } from "../../../../theme/gradients.js";

import "./styles.css";

const openHubSpotChat = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open();
  } else {
    console.error("HubSpot Chat no está cargado aún.");
  }
};

const link_whatsapp = `https://wa.me/+5491157617111?text=${strings.WHATSAPP.TEXT_LINK}`;

const ActionButton = ({ theme, icon, label, onClick }) => (
  <Button
    startIcon={icon}
    onClick={onClick}
    sx={{
      mt: 5,
      p: 1,
      backgroundColor: theme.palette.primary.main,

      "&:hover": { backgroundColor: theme.palette.action.hover },
      borderRadius: "20px",
      color: "white !important",
      minWidth: { xs: "60vw", md: "15vw" },
    }}
  >
    {label}
  </Button>
);

const BioSection = ({ leaderIds = [10] }) => {
  const theme = useTheme();

  const slideConfigs = Array.isArray(leaderIds)
    ? leaderIds.map((id) => strings.Bio[id])
    : [];

  return (
    <Grid
      container
      sx={{
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          background: gradients.generalGradient,
          minHeight: "100vh",
        }}
      >
        {slideConfigs.map((Bio, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <CardBio
              name={Bio?.NAME}
              rol={Bio?.ROL}
              description={Bio?.DESCRIPTION}
              avatar={Bio?.AVATAR}
              avatarAlignment={"left"}
            />
          </Grid>
        ))}
        {/*  */}
        {leaderIds[0] === 10 ? (
          <>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={1}></Grid>

              <Grid item xs={3}>
                <ActionButton
                  theme={theme}
                  icon={
                    <WhatsAppIcon
                      sx={{
                        color: "white !important",
                      }}
                    />
                  }
                  label={strings.WHATSAPP.CHAT_WITH_US}
                  onClick={() => window.open(link_whatsapp, "_blank")}
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  theme={theme}
                  icon={
                    <CalendarMonthIcon
                      sx={{
                        color: "white !important",
                      }}
                    />
                  }
                  label={strings.WHATSAPP.SCHEDULE_MEETING}
                  onClick={() =>
                    window.open(
                      "https://meetings.hubspot.com/david3299",
                      "_blank"
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        {leaderIds[0] === 22 ? (
          <>
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={1}></Grid>

              <Grid item xs={3}>
                <ActionButton
                  theme={theme}
                  icon={
                    <WhatsAppIcon
                      sx={{
                        color: "white !important",
                      }}
                    />
                  }
                  label={strings.WHATSAPP.CHAT_WITH_US}
                  onClick={openHubSpotChat}
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <ActionButton
                  theme={theme}
                  icon={
                    <CalendarMonthIcon
                      sx={{
                        color: "white !important",
                      }}
                    />
                  }
                  label={strings.WHATSAPP.SCHEDULE_MEETING}
                  onClick={() =>
                    window.open(
                      "https://meetings.hubspot.com/ethan302",
                      "_blank"
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        {/*  */}
      </Grid>
    </Grid>
  );
};

export default BioSection;
