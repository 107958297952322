import { createTheme } from '@mui/material';


export const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#FFFFFF",
      default: "#707070",
    },
    primary: {
      main: "#A0730C",
      black: "#000000",
      white: "#FFFFFF",
      grey: '#707070', 
	  greyDark:"#364049",
      whatsapp: "#25D366",
    },
    action: {
      hover: "rgba(160,160,160,1)",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "#FFFFFF",
          "&::placeholder": {
            color: "#FFFFFF",
            opacity: 1,
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ["Raleway", "Roboto"].join(","),
  },
});

export default theme;
