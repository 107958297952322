import React, { useRef, useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  Grid,
  Typography,
  Box,
  Button,
  Input,
  FormControl,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ChevronRight } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
/* import ReCAPTCHA from "react-google-recaptcha";
 */
import FooterSection from "../Footer";
import sendEmail from "../../services/emailjs";
import { strings } from "../../core/utils/strings";
import { gradients } from "../../theme/gradients";
import { link_whatsapp } from "../../core/utils/contants";
import "react-toastify/dist/ReactToastify.css";

const yupSchema = yup
  .object({
    email: yup
      .string()
      .email()
      .required(),
    name: yup.string().required(),
    phone: yup.string().required(),
    message: yup.string().required(),
  })
  .required();

const defaultValues = {
  email: "",
  name: "",
  phone: "",
  message: "",
};

const FormInput = ({ name, control, placeholder, multiline, rows }) => {
  const theme = useTheme();
  const isMultiline = multiline || false;
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal">
          {isMultiline ? (
            <TextField
              {...field}
              placeholder={placeholder}
              multiline={isMultiline}
              rows={rows || 1}
              variant="standard"
              error={!!error}
              InputProps={{
                style: {
                  color: theme.palette.primary.white,
                  paddingLeft: "10px",
                },
              }}
            />
          ) : (
            <Input
              {...field}
              placeholder={placeholder}
              error={!!error}
              inputProps={{
                style: {
                  color: theme.palette.primary.white,
                },
              }}
              style={{
                color: theme.palette.primary.white,
                paddingLeft: "10px",
              }}
            />
          )}
        </FormControl>
      )}
    />
  );
};

const ContactSection = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para gestionar el envío

  const { control, handleSubmit, reset, trigger } = useForm({
    resolver: yupResolver(yupSchema),
    mode: "onBlur", // Cambiado de all a onblur
    defaultValues,
  });

  trigger();

  // Reset formulario
  useEffect(() => {
    if (isSent) {
      reset(defaultValues);
      setIsSent(false);
    }
  }, [reset, isSent]);
  /*   const siteKey = process.env.REACT_APP_SITE_KEY;

   const [recaptchaToken, setRecaptchaToken] = useState(null);

   const onChange = (token) => {
    setRecaptchaToken(token);
   }; */

  const formRef = useRef();

  const onSubmit = async (data) => {
    setIsSubmitting(true); 
    /*     if (!recaptchaToken) {
       toast.error("Please complete the reCAPTCHA");
       return;
     } */
    const sent = await sendEmail(formRef.current);

    if (sent) {
      toast.success(strings.RESPONSES.SUCCESS);
      setIsSent(true);
      /*        setRecaptchaToken(null);
       */
    } else {
      toast.error(strings.RESPONSES.FAILED);
    }

    setIsSubmitting(false); 
  };

  return (
    <>
      <Grid
        id="contact"
        container
        sx={{
          background: gradients.footerGradient,
          height: "100%",
          mt: matchesSM ? 6 : 2,
          px: matchesSM ? 2 : 10,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            mx: "auto",
            display: { xs: "block", md: "none" },
            height: "8vh",
          }}
        >
          <Typography
            fontFamily={"Raleway"}
            fontSize={35}
            sx={{
              pt: { xs: 5, sm: 10 },
              pb: { xs: 0, sm: 0 },
              color: theme.palette.primary.white,
              fontWeight: 700,
              marginTop: { xs: 3, sm: 0 },
            }}
          >
            {strings.CONTACT}
          </Typography>

          <Box
            sx={{
              width: "20%",
              height: "8px",
              backgroundColor: theme.palette.primary.main,
              marginBottom: "1rem",
            }}
          />

          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.white }}
          >
            {strings.CONTACT_DESCRIPTION}
          </Typography>
        </Grid>
        <ContactActions theme={theme} />
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{ mx: "auto", display: { xs: "none", md: "block" } }}
        >
          <Typography
            fontSize={matches ? 44 : 10}
            fontFamily={"Raleway"}
            gutterBottom
            sx={{
              pt: 8,
              color: theme.palette.primary.white,
              fontWeight: 700,
              whiteSpace: "nowrap",
            }}
          >
            {strings.CONTACT}
          </Typography>
          <Box
            sx={{
              width: "20%",
              height: "8px",
              backgroundColor: theme.palette.primary.main,
              marginBottom: "1rem",
            }}
          />

          <Typography
            variant="p"
            sx={{ color: theme.palette.primary.white, pb: 0 }}
          >
            {strings.CONTACT_DESCRIPTION}
          </Typography>
          <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            style={{ minWidth: "300px" }}
          >
            <FormInput
              name="name"
              control={control}
              placeholder={strings.CONTACT_FORM.NAME}
            />
            <FormInput
              name="email"
              control={control}
              placeholder={strings.CONTACT_FORM.EMAIL}
            />
            <FormInput
              name="phone"
              control={control}
              placeholder={strings.CONTACT_FORM.PHONE}
            />
            <FormInput
              name="message"
              control={control}
              placeholder={strings.CONTACT_FORM.MESSAGE}
              multiline
              rows={3}
            />

            <Grid
              container
              direction="column"
              spacing={0}
              alignItems="center"
              p={1}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                endIcon={
                  isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    <ChevronRight />
                  )
                }
                disabled={isSubmitting} // Deshabilitar el botón mientras se envía
                sx={{
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                {strings.CONTACT_FORM.SEND.toUpperCase()}
              </Button>
              {/*      <Grid p={2}>
                <ReCAPTCHA
                  style={{ overflow: "hidden" }}
                  sitekey={siteKey}
                  onChange={onChange}
                />
              </Grid> */}
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ margin: { xs: 1, md: 0 } }} />
          <FooterSection />
        </Grid>
      </Grid>
      <ToastContainer
        position="bottom-rigth"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
   
    </>
  );
};


const imageWidth = 600;
const imageHeight = 187;

export const ContactActions = ({ theme }) => (


  <Grid
    item
    xs={12}
    md={6}
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      mt: 4,
      height: "50vh",
    }}
  >
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
       <img
            src={"logos/smiles.avif"}
            width={imageWidth} 
            height={imageHeight} 
            style={{ maxWidth: "100%", height: "96%" }}
            alt="TogetherDevs"
             loading="lazy"
          />
    </Box>
    <ActionButton
      theme={theme}
      icon={
        <WhatsAppIcon
          sx={{
            color: "white !important",
          }}
        />
      }
      label={strings.WHATSAPP.CHAT_WITH_US}
      onClick={() => window.open(link_whatsapp, "_blank")}
      sx={{
        mt: 2,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "20px",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    />
    <ActionButton
      theme={theme}
      icon={
        <CalendarMonthIcon
          sx={{
            color: "white !important",
          }}
        />
      }
      label={strings.WHATSAPP.SCHEDULE_MEETING}
      onClick={() =>
        window.open("https://meetings.hubspot.com/david3299", "_blank")
      }
    />
  </Grid>
);

const ActionButton = ({ theme, icon, label, onClick }) => (
  <Button
    startIcon={icon}
    onClick={onClick}
    sx={{
      mt: 5,
      p: 1,
      backgroundColor: theme.palette.primary.main,
      "&:hover": { backgroundColor: theme.palette.action.hover },
      borderRadius: "20px",
      color: "white !important",
      minWidth: { xs: "48vw", md: "15vw" },
    }}
  >
    {label}
  </Button>
);

export default ContactSection;
