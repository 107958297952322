import emailjs from '@emailjs/browser';


const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

emailjs.init(publicKey);

const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

const sendEmail = (form) => {
    return emailjs.sendForm(serviceID, templateID, form)
      .then((result) => {
          console.log('Email successfully sent!', result.text);
          return true; 
      })
      .catch((error) => {
          console.log('Email send failed:', error.text);
          return false; 
      });
  }

export default sendEmail;