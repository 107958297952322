import * as React from "react";
import { useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


export default function ChipsArray({
  matches,
  title,
  swiperRef,
  onChipChange,
  selectedChip,
  children,
}) {
  const theme = useTheme();

  const chipData = title.map((item, index) => ({
    key: index,
    label: item.title,
  }));

  const handleClick = (chipClicked) => () => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(chipClicked.key);
    }
    if (onChipChange) {
      onChipChange(chipClicked.key);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: matches ? "center" : "flex-start",
          listStyle: "none",
          p: 0.5,
          m: 0.5,
          zIndex: 1,
        }}
        component="ul"
      >
        {matches === "desktop" &&
          chipData.map((data) => (
            <ListItem key={data.key}>
              <Chip
                label={data.label}
                onClick={handleClick(data)}
                sx={{
                  backgroundColor:
                    selectedChip === data.key
                      ? theme.palette.primary.main
                      : theme.palette.primary.grey,
                  color: theme.palette.primary.white,
                  fontSize: 18,
                  fontWeight: 600,
                  borderRadius: "16px",
                  margin: matches ? "0.1rem" : "0.5rem",
                  "&:hover": {
                    backgroundColor:
                      selectedChip === data.key
                        ? theme.palette.primary.main
                        : theme.palette.primary.grey,
                  },
                  "&:focus": {
                    backgroundColor:
                      selectedChip === data.key
                        ? theme.palette.primary.main
                        : theme.palette.primary.grey,
                  },
                  "&:active": {
                    backgroundColor:
                      selectedChip === data.key
                        ? theme.palette.primary.main
                        : theme.palette.primary.grey,
                  },
                }}
              />
            </ListItem>
          ))}
        {matches !== "desktop" && (
          <ListItem key={chipData[selectedChip].key}>
            <Chip
              label={chipData[selectedChip].label}
              onClick={handleClick(chipData[selectedChip])}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.white,
                fontSize: 18,
                fontWeight: 500,
                borderRadius: "16px",
                margin: matches ? "0.2rem" : "0.5rem",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                "&:focus": {
                  backgroundColor: theme.palette.primary.grey,
                },
                "&:active": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            />
          </ListItem>
        )}
      </Grid>
      <Box sx={{ width: "100%", height: "fit-content", mt: 2 }}>{children}</Box>
    </Box>
  );
}
