import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { strings } from "../../core/utils/strings";
import { gradients } from "../../theme/gradients";

const AboutSection = () => {
  const theme = useTheme();
  
  const imageWidth = 600;
  const imageHeight = 187; 

  return (
    <Grid
      container
      sx={{
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
        paddingTop: { xs: "1rem", md: "2rem" },
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          border: "2px",
          borderRadius: "20px",
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginX: { xs: 2, md: 0 },
        }}
      >
        <Typography
          component="h2"
          fontFamily={"Raleway"}
          gutterBottom
          sx={{
            color: theme.palette.primary.white,
            fontWeight: 400,
            lineHeight: "1.3",
            fontSize: { xs: "2rem", sm: "3rem", md: "3rem", lg: "5rem" },
            textAlign: "center",
            padding: { xs: 0, md: 4 },
          }}
        >
          {strings.INTERESTED.toUpperCase()}
        </Typography>
      </Grid>
      
      <Grid
        item
        xs={0}
        md={6}
        lg={4}
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <img
            src={"logos/smiles.avif"}
            width={imageWidth} 
            height={imageHeight} 
            style={{ maxWidth: "100%", height: "100%" }}
            alt="TogetherDevs"
            loading="lazy"  
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
          }}
        >
          <img
            src={"logos/smiles.avif"}
            width={imageWidth} 
            height={imageHeight} 
            style={{ maxWidth: "100%", height: "100%" }}
            alt="TogetherDevs"
            loading="lazy"  
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={5}
        sx={{
          marginTop: { xs: 2, sm: 4, md: 6 },
        }}
      >
        <Typography
          component="p"
          gutterBottom
          fontFamily={"Raleway"}
          sx={{
            p: { xs: 2, sm: 2, md: 2 },
            color: theme.palette.primary.white,
            fontWeight: 700,
            fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.4rem", lg: "1.8rem" },
            lineHeight: "1.3",
            textAlign: "center",
          }}
        >
          {strings.INTERESTED_DESCRIPTION}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutSection;
