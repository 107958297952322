import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

export default function CardService({ title, description, image }) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.black,
        borderRadius: 4,
        minHeight: 350,
      }}
    >
      <CardMedia
        component="img"
        height="200"
        maxWidth={"350"}
        image={image}
        alt={title}
        sx={{ objectFit: "cover", borderRadius: 4 }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          gutterBottom
          component="h3"
          fontFamily={"Raleway"}
          color={theme.palette.primary.main}
          sx={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.primary.white}
          fontFamily={"Raleway"}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
