import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { gradients } from "../../theme/gradients";
import { strings } from "../../core/utils/strings";

const technologiesRoute = [
  "aws",
  "docker",
  "mongodb",
  "net", 
  "node",
  "react",
  "sql",
];

const Technologies = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("sm")); 
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm")); 

  const getImageSize = () => {
    if (matchesXs) return { width: 50, height: 50 };
    if (matchesSm) return { width: 90, height: 90 };
    return { width: 100, height: 100 };
  };

  const imageSize = getImageSize();

  return (
    <Box
      sx={{
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        padding: "20px",
      }}
    >
      <Box>
        <Typography
          component="h2"
          fontFamily={"Raleway"}
          gutterBottom
          sx={{
            color: theme.palette.primary.white,
            fontWeight: 700,
            fontSize: { xs: "2rem", sm:"2.2rem"},
            marginTop: { xs: 2, sm:8 },
          }}
        >
          {strings.TECHNOLOGIES_TITLE}
        </Typography>

        <Box
          sx={{
            width: "20%",
            height: "8px",
            backgroundColor: theme.palette.primary.main,
            marginBottom: { xs: '0rem', sm: '5rem' },
          }}
        />
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {technologiesRoute.map((technology, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`technologies/${technology}.png`}
              width={imageSize.width} 
              height={imageSize.height}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain"
              }}
              alt={`${technology}`}
               loading="lazy"
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Technologies;
