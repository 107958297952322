import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { strings } from "../../../core/utils/strings";
import CardService from "../../../components/Cards/CardService";
import { useActiveSection } from "../../../context/ActiveSection.context";
import { gradients } from "../../../theme/gradients";

import "../Desktop/styles.css";

const ServicesSectionMobile = () => {
  const theme = useTheme();
  const { activeSection } = useActiveSection();
  const slideConfig =
    strings.SERVICES[activeSection + 1] || strings.SERVICES[4];

  return (
    <Container
      maxWidth="lg"
      sx={{
        my: { xs: 12, sm: 8 },
        height: { sm: "100vh", xs: "100vh" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        background: gradients.generalGradient,
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        fontFamily={"Raleway"}
        sx={{
          pt: 12,
          pb: { xs: 0, sm: 2 },
          color: theme.palette.primary.white,
          fontWeight: 700,
          fontSize: "2rem"

        }}
      >
        {strings.SERVICES_TITLE}
      </Typography>

      <Box
        sx={{
          width: "20%",
          height: "8px",
          backgroundColor: theme.palette.primary.main,
        }}
      />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "4rem" }}
      >
        <Grid item xs={12} sm={6} md={4} key={activeSection}>
          <CardService
            title={slideConfig.TITLE}
            description={slideConfig.DESCRIPTION}
            image={slideConfig.IMAGE}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicesSectionMobile;
