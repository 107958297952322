export const strings = {
  NAME_COMPANY: "Together Devs",
  HERO: {
    SLIDE_ONE: {
      TITLE: "it staff augmentation",
      DESCRIPTION: "Growing together let our team augment yours.",
      BUTTON: "CONTACT US",
    },
    SLIDE_TWO: {
      TITLE: "Managed Teams",
      DESCRIPTION: "Working together to enhance your workforce",
      BUTTON: "CONTACT US",
    },
    SLIDE_THREE: {
      TITLE: "Product Development",
      DESCRIPTION: "Together we elevate your team capabilities",
      BUTTON: "CONTACT US",
    },
  },
  SERVICES_TITLE: "Our services",
  PORTFOLIO_TITLE: "Success Cases",
  TECHNOLOGIES_TITLE: "Our technologies",
  SERVICES: {
    4: {
      TITLE: "Product Development",
      DESCRIPTION:
        "Maximize your product development by seamlessly blending different business models",
      IMAGE: "services/1.avif",
    },
    5: {
      TITLE: "Managed Teams",
      DESCRIPTION:
        "Maximize the effectiveness of your managed teams by seamlessly blending different business models",
      IMAGE: "services/2.avif",
    },
    6: {
      TITLE: "IT staff augmentation",
      DESCRIPTION:
        "Enhance your IT staff augmentation strategy by seamlessly blending different business models",
      IMAGE: "services/3.avif",
    },
  },
  OURLEADERS_TITLE: "Our Leaders",
  OURLEADERS_BUTTON_SALES: "Hire Us",
  OURLEADERS_BUTTON_CEO: "Meet Our Team",
  OURLEADERS: {
    10: {
      NAME: "DAVID LEVY",
      ROL: "FOUNDER & CEO",
      DESCRIPTION: '"The best way to predict the future is to invent it"',
      AVATAR: "ourTeam/CEO.avif",
    },
    12: {
      NAME: "MATEO LEVY",
      ROL: "CTO",
      DESCRIPTION:
        '"Together we transform your vision into reality, driving innovation and excellence."',
      AVATAR: "ourTeam/CTO.avif",
    },
    22: {
      NAME: "ETHAN HARRIS",
      ROL: "Business Partner",
      DESCRIPTION:
        '"We are here to turn your ideas into innovative and efficient digital experiences."',
      AVATAR: "ourTeam/Ethan Harris.avif",
    },
  },

  TEAM_TITLE: "Our Team",

  TEAM: {
    13: {
      HASHTAG:
        "#Javascript  #React #TypeScript #Nodejs #SQL #ProjectManagement #RelationshipManagement",
      NAME: "Agustin Rodriguez",
      ROL: "Software developer - Project Manager",
      DESCRIPTION:
        '"In a culture centered on individuality, there is no greater revolution than collective achievements."',
      BIO: " With 10 years of experience as an independent professional, I have provided innovative products and services, learning, creating, growing, and sharing within a team. My focus is on technological innovation and personal development.",
      AVATAR: "ourTeam/Agustin Rodriguez.png",
    },
    14: {
      HASHTAG: "#Javascript #React  #TypeScript #NextJs #Nodejs",
      NAME: "Carlos Actis",
      ROL: "Full Stack Developer",
      DESCRIPTION:
        '"Passionate about crafting dynamic user interfaces that enhance user experience and drive business success."',
      BIO: "As a seasoned Front-end Developer with over five years of experience, I am passionate about crafting dynamic user interfaces that enhance user experience and driving business into success. My career is marked by a continuous pursuit of learning and applying cutting-edge technologies providing innovative, high-quality solutions. I am a collaborative team member, constantly pushing for the integration of the latest design trends and coding standards to ensure the production of robust and scalable web applications.",
      AVATAR: "ourTeam/Carlos Actis.png",
    },
    15: {
      HASHTAG:
        "#Javascript #Nodejs #React #ReactNative #Architecture #Leadership",
      NAME: "Carlos Martin",
      ROL: "Full Stack Developer",
      DESCRIPTION:
        '"My approach is a deep commitment to using the latest industry standards for both frontend and backend technologies, ensuring that every project is not only technically right but also aligns with evolving market trends."',
      BIO: "As a seasoned Team Lead and Full Stack Senior Developer, I bring over seven years of rich experience in the software industry. My journey has been marked by successful collaborations with industry giants such as YoCripto, CNH, Accenture, Microsoft, Price Shoes, Data Art, and Beamersoft, among others. My forte lies in breathing life into top-tier applications, from inception to market launch. My expertise encompasses the full spectrum of application development, including designing innovative e-commerce solutions, leading cross-platform application projects, and developing cutting-edge VOD applications. At the core of my approach is a deep commitment to using the latest industry standards for both frontend and backend technologies, ensuring that every project I undertake is not only technically sound but also aligns with evolving market trends.",
      AVATAR: "ourTeam/Carlos Martin.png",
    },
    16: {
      HASHTAG: "#javascript #Nextjs #React #Python #TechnicalLeadership",
      NAME: "Augusto Romero",
      ROL: "Full Stack Developer",
      DESCRIPTION:
        '"I am passionate about merging software development with artificial intelligence to drive innovation."',
      BIO: "As a seasoned Team Lead and Full Stack Senior Developer, I bring over seven years of rich experience in the software industry. My journey has been marked by successful collaborations with industry giants such as YoCripto, CNH, Accenture, Microsoft, Price Shoes, Data Art, and Beamersoft, among others. My forte lies in breathing life into top-tier applications, from inception to market launch. My expertise encompasses the full spectrum of application development, including designing innovative e-commerce solutions, leading cross-platform application projects, and developing cutting-edge VOD applications. At the core of my approach is a deep commitment to using the latest industry standards for both frontend and backend technologies, ensuring that every project I undertake is not only technically sound but also aligns with evolving market trends.",
      AVATAR: "ourTeam/Augusto Romero.png",
    },
    17: {
      HASHTAG: "#javascript #.net #C# #React #ReactNative #PHP #AWS ",
      NAME: "Miguel Maidana",
      ROL: "Full Stack Developer",
      DESCRIPTION: '"Passionate about learning"',
      BIO: " I am a Full Stack developer with 10 years of experience. I am characterized by constantly learning new technologies and striving to deliver the best quality code when facing different technological challenges.",
      AVATAR: "ourTeam/Miguel Maidana.png",
    },
    18: {
      HASHTAG:
        "#React #Angular #PHP #ReactNative #Ionic #Nodejs #Django #Python #MongoDB",
      NAME: "Facundo Varela",
      ROL: "Full Stack Developer",
      DESCRIPTION:
        '"10 years of experience, I focus on ensuring that every product we deliver not only meets but exceeds industry standards."',
      BIO: '"As a seasoned Full Stack Developer with over 10 years of dedicated experience in the tech industry, my journey has been one of continuous growth and innovation. My expertise spans across the MERN stack, coupled with a deep understanding of UX/UI design principles cultivated over more than 7 years in development roles.Currently, as a valued member of the Together Devs team, I am entrusted with spearheading full-stack development initiatives, particularly in PHP projects. My role goes beyond mere coding I am an integral part of the process, from conceptualization to deployment, ensuring that every product we deliver not only meets but exceeds industry standards."',
      AVATAR: "ourTeam/Facundo Varela.png",
    },
    19: {
      HASHTAG:
        "#EffectiveCommunication #StrategicPlanning #ProjectManagement #RelationshipManagement",
      NAME: "Eugenia Galleguillo",
      ROL: "Marketing & Communication",
      DESCRIPTION:
        '"Life is not about waiting for the storm to pass but about learning to dance in the rain"',
      BIO: "Armed with a degree in Communication, over five years of industry experience and fervent dedication to facilitating idea transmission, I am deeply passionate about collaborative endeavors aimed at achieving collective goals. My commitment lies in synergizing efforts to effectively convey messages and visions. By fostering teamwork and leveraging my expertise, I aspire to contribute meaningfully to your projects. Together, we can not only meet objectives but surpass them, reshaping industry norms and driving impactful change",
      AVATAR: "ourTeam/Eugenia Galleguillo.png",
    },

    20: {
      HASHTAG: "#PHP #WordPress #Woocommerce #Javascript #CSS",
      NAME: "Fernando Gamba",
      ROL: "PHP Developer",
      DESCRIPTION: '"There is no progress without passion."',
      BIO: "I've been an Informatics Engineer since 2011 and a Web Developer since 2007. I've been involved in the development of wonderful sites and challenging projects like mygcsg.com and riaa.com. I have a long experience in WordPress and Woocommerce and I've been involved in all kinds of projects from creating simple websites to complex themes and custom plugins. I am from Argentina, which allows me to have real-time communications with the US and Europe as our time is UTC-3. I never, ever leave a project halfway even if I had to work double or triple, I never give up. I like to build fluent relationships with my clients, stay in contact, keeping good communication, and always try to clarify expectations to get things right the way people want.",
      AVATAR: "ourTeam/Fernando Gamba.png",
    },
    21: {
      HASHTAG: "#.net #c# #SQL #ProductManagement #AWS ",
      NAME: "Santiago Martinez",
      ROL: "Backend Developer",
      DESCRIPTION:
        '"Driven by a passion for excellence and a commitment to quality, I craft flawless solutions where precision meets innovation."',
      BIO: "As a developer fluent in .NET, C#, SQL and AWS, my journey is defined by a steadfast commitment to good practices, optimization, and solid design principles. With an eye for detail and a passion for perfection, I ensure that every line of code I write is not just functional, but flawless. From adhering to SOLID principles to crafting scalable architectures, I prioritize reliability and excellence in every project I undertake. Proactively seeking out opportunities for improvement, I drive innovation and efficiency, ensuring that my solutions not only meet but exceed expectations. Join me in crafting software solutions that stand the test of time, where precision meets passion, and innovation knows no bounds.",
      AVATAR: "ourTeam/Santiago Martinez.png",
    },
  },
  Bio: {
    10: {
      NAME: "DAVID LEVY",
      ROL: "FOUNDER & CEO",
      DESCRIPTION:
        " As a Technical Entrepreneur and having dedicated more than 20 years in Technology I've realized my passion for collaborating closely with our clients. Understanding their unique needs fuels my drive to reimagine today’s conventional approaches, utilizing cutting-edge tools to enhance efficiency and foster innovation. My mission it's about elevating our clients' value propositions, enriching their service offerings, and crafting superior user experiences. At Together Devs, we embark on a journey of technological evolution, where innovation is more than just a concept—it's a transformative force reshaping industries and unlocking endless possibilities.",
      AVATAR: "ourTeam/CEO.avif",
    },
    12: {
      NAME: "MATEO LEVY",
      ROL: "CTO",
      DESCRIPTION:
        "With almost 10 years dedicated to technology, I've honed a deep-seated passion for client-centric collaboration. My focus is on understanding their unique needs, leveraging cutting-edge tools to enhance efficiency and drive innovation. My mission extends beyond mere solutions—I'm committed to elevating our clients' value propositions, enriching their offerings, and delivering superior user experiences. Leading software initiatives globally, I serve as a catalyst for transformative change, driving innovation and maximizing value for our clients. Let's embark on a journey together, where my expertise as a CTO adds tangible value to your endeavors, propelling your business forward and reshaping industry standards.",
      AVATAR: "ourTeam/CTO.avif",
    },

    22: {
      NAME: "ETHAN HARRIS",
      ROL: "Business Partner",
      DESCRIPTION:
        "As Business Partner at TogetherDevs, I'm excited to help you turn your app ideas into innovative digital experiences. Schedule a meeting through my profile page on our website to share your ideas, and together we'll explore how our team can develop a custom solution that exceeds expectations. Our team is dedicated to delivering high-quality software that stands out in the market. Let's build something amazing together!",
      AVATAR: "ourTeam/Ethan Harris.avif",
    },
  },

  TESTIMONIALS_TITLE: "Client testimonials",

  NAV_ITEMS: [
    "Home",
    "Services",
    "Success Cases",
    "About Us",
    "Testimonials",
    "Contact",
  ],
  INTERESTED: "Interested in working together?",
  INTERESTED_DESCRIPTION:
    "Our goal is to provide the necessary technology and innovation, allowing you to concentrate solely on successfully completing the shutdown process for your website. Consider us your support team and strategic partner.",
  CONTACT: "Contact us",
  CONTACT_DESCRIPTION: "Start to monetize today!",
  CONTACT_FORM: {
    NAME: "Name",
    EMAIL: "Email",
    PHONE: "Phone",
    MESSAGE: "Message",
    SEND: "Send",
  },
  FOOTER: {
    ADDRESS: "+44 7488 360321 | Dallas,TX, US",
    ALL_RIGHTS_RESERVED: "© 2024 TogetherDevs. All Rights Reserved.",
    FOLLOW_US: "Follow us",
  },
  PORTFOLIO: {
    VOD_PLATFORM: "Media Entertainment",
    ECOMMERCE: "E-Commerce",
    FINANCE_ADVISORS: "Fintech Industry",
    CUSTOM_DEVELOPMENT: "Custom health",
  },
  RESPONSES: {
    SUCCESS: "Email successfully sent!",
    FAILED: "Failed to send email. Please try again later.",
  },
  WHATSAPP: {
    CHAT_WITH_US: "Chat with us",
    SCHEDULE_MEETING: "Schedule a Meeting",
    TEXT_LINK:
      "Hello%20Together%2C%20I%20wanted%20to%20inquire%20about%20your%20services.%20Could%20you%20assist%20me%2C%20please%3F",
  },
};


