import React from "react";
import { strings } from "../../core/utils/strings";
import { useActiveSection } from "../../context/ActiveSection.context";
import { useSwiperContext } from "../../context/SwiperPage.context";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { link_whatsapp } from "../../core/utils/contants";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const string = { NAV_ITEMS: ["Home", "Business Partner", "Contact"] };

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const { activeSection, setActiveSection } = useActiveSection();
  const { swiperInstance } = useSwiperContext();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const navigate = useNavigate();


  const navigateToSlide = (index) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigation = (index) => {
    if (index === 0) {
      setActiveSection(0); 
      navigate("/home");
    } else {
      navigateToSlide(index - 1); 
      setActiveSection(index); 
    }
  };

  const isSectionActive = (index) => {
    if (index === 0) return false; 
    return index === activeSection;
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        onClick={() => handleNavigation(0)}
        sx={{ cursor: "pointer" }}
        py={2}
      >
        <img
          src={"/logos/TogetherDevs.svg"}
          height="40px"
          width="100%"
          alt="TogetherDevs"
           loading="lazy"
        />
      </Box>
      <Divider />
      <List>
        {string["NAV_ITEMS"].map((item, index) => (
          <ListItem key={item} onClick={() => handleNavigation(index)}>
            <ListItemButton
              sx={{
                textAlign: "center",
                backgroundColor: isSectionActive(index)
                  ? theme.palette.primary.main
                  : "transparent",
                color: theme.palette.primary.white,
              }}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <ListItemButton component="a" href={link_whatsapp} target="_blank">
            <WhatsAppIcon
              sx={{
                marginRight: theme.spacing(3.5),
                color: "#46CC6B",
              }}
            />
            <ListItemText
              primary={strings.WHATSAPP.CHAT_WITH_US}
              sx={{ color: theme.palette.primary.white }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: theme.palette.primary.black }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {matches ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleNavigation(0)}
              sx={{ cursor: "pointer" }}
            >
              <img
                src={"/logos/TogetherDevs.svg"}
                height="40px"
                width="100%"
                alt="TogetherDevs"
                 loading="lazy"
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
              />

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                onClick={() => handleNavigation(0)}
                sx={{ cursor: "pointer" }}
              >
               <img
  src={"/logos/TogetherDevs.svg"}
  height="40px"
  width="100%"
  alt="TogetherDevs"
   loading="lazy"
/>
              </Box>

              <Box
                sx={{ flexGrow: 2, display: { xs: "block", sm: "block" } }}
              />
            </>
          )}
          <IconButton
            aria-label="chat with us on WhatsApp"
            component="a"
            href={link_whatsapp}
            target="_blank"
            sx={{
              display: { xs: "flex", sm: "none" },
              position: "fixed",
              right: "10px !important",
              color: `${theme.palette.primary.whatsapp} !important`,
            }}
          >
            <WhatsAppIcon />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              paddingRight: 5,
              justifyContent: "space-between",
              maxWidth: "800px",
            }}
          >
            {string["NAV_ITEMS"].map((item, index) => {
              const isActive = isSectionActive(index);
              return (
                <Button
                  key={item}
                  sx={{
                    "&:hover": {
                      backgroundColor: !isActive
                        ? theme.palette.action.hover
                        : theme.palette.primary.main,
                    },
                    color: "#fff",
                    width: "100%",
                    backgroundColor: isActive
                      ? theme.palette.primary.main
                      : "transparent",
                  }}
                  onClick={() => handleNavigation(index)}
                >
                  {item}
                </Button>
              );
            })}
            <IconButton
              aria-label="chat with us on WhatsApp"
              component="a"
              href={link_whatsapp}
              target="_blank"
              sx={{
                display: { xs: "flex", sm: "flex" },
                position: "fixed",
                right: "10px !important",
                color: `${theme.palette.primary.whatsapp} !important`,
              }}
            >
              <WhatsAppIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.primary.black,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default NavBar;
