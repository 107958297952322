import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { strings } from "../../../core/utils/strings";
import CardOurLeaders from "../../../components/Cards/CardOurLeaders";
import { gradients } from "../../../theme/gradients";
import { useNavigate, useLocation } from "react-router-dom";

const OurTeamSection = () => {
  const slideConfigs = Object.values(strings.OURLEADERS);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const handleButtonTeam = () => {
    navigate("/managed-teams");
  };
  const handleButtonSales = () => {
    navigate("/businessPartner");
  };
  return (
    <Grid
      container
      sx={{
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
        paddingTop: { xs: "0rem", md: "0rem" },
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid item spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            border: "2px",
            borderRadius: "20px",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginX: { xs: 0, md: 0 },
          }}
        >
          <Box>
            <Typography
              component="h2"
              fontFamily={"Raleway"}
              gutterBottom
              sx={{
                color: theme.palette.primary.white,
                fontWeight: 700,
                fontSize: { xs: 40, md: 50 },
              }}
            >
              {strings.OURLEADERS_TITLE}
            </Typography>

            <Box
          sx={{
            width: "20%",
            height: "8px",
            backgroundColor: theme.palette.primary.main,
            marginBottom: { xs: '0rem', sm: '5rem' },
          }}
        />
          </Box>
        </Grid>

        {slideConfigs.map((leaders, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            key={index}
            sx={{ marginBottom: { xs: 0, md: 0 } }}
          >
            <CardOurLeaders
              name={leaders.NAME}
              rol={leaders.ROL}
              description={leaders.DESCRIPTION}
              avatar={leaders.AVATAR}
              avatarAlignment={index % 2 === 0 ? "left" : "right"}
            />
          </Grid>
        ))}

        {location.pathname !== "/managed-teams" && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2}></Grid>

            <Grid item xs={3}>
              <ActionButton
                theme={theme}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={handleButtonSales}
                label={strings.OURLEADERS_BUTTON_SALES}
              />
            </Grid>
            <Grid item xs={2}></Grid>

            <Grid item xs={3}>
              <ActionButton
                theme={theme}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={handleButtonTeam}
                label={strings.OURLEADERS_BUTTON_CEO}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ActionButton = ({ theme, icon, label, onClick }) => (
  <Button
    startIcon={icon}
    onClick={onClick}
    sx={{
      mt: 5,
      p: 1,
      backgroundColor: theme.palette.primary.main,
      "&:hover": { backgroundColor: theme.palette.action.hover },
     borderRadius: "20px",
      color: "white !important",
      minWidth: { xs: "40vw", md: "15vw" },
      maxWidth: { xs: "40vw", md: "20vw" },
    }}
  >
    {label}
  </Button>
);

export default OurTeamSection;
