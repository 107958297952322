import React, { useEffect } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import NavBar from "../../components/NavBar/businesspartner";
import { useActiveSection } from "../../context/ActiveSection.context";
import { useSwiperContext } from "../../context/SwiperPage.context";
import { sectionsDesktop, sectionsMobile } from "../../core/utils/contents/sales";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

const BusinessPartner = () => {
  const { swiperInstance, setSwiperInstance } = useSwiperContext();
  const { setActiveSection } = useActiveSection();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const setSection = matches ? sectionsDesktop : sectionsMobile;


  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.on("slideChange", () => {
        setActiveSection(swiperInstance.activeIndex);
      });
    }
  }, [swiperInstance, setActiveSection]);

  return (
    <>
      <NavBarWrapper />
      <CustomSwiper
        setSection={setSection}
        setSwiperInstance={setSwiperInstance}
      />
    </>
  );
};

const NavBarWrapper = () => (
  <Box component="nav">
    <Container maxWidth={false} disableGutters>
      <NavBar />
    </Container>
  </Box>
);

const CustomSwiper = ({ setSection, setSwiperInstance }) => {
  return (
    <Swiper
      onSwiper={(swiper) => setSwiperInstance(swiper)}
      direction="vertical"
      slidesPerView={1}
     mousewheel
      pagination={{ clickable: true, bullet: false }}
      modules={[Mousewheel]}
       className="swiperPage"
    >
      {setSection.map((SectionComponent, index) => (
        <SwiperSlide key={index}>
          <SectionComponent />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BusinessPartner;
