import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

const CardPost = ({ title, author, date, content, imageUrl, metaDescription }) => {
  const theme = useTheme();

  return (
    <Card
      component="article"
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        height: 400,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
        },
        margin: 2,
      }}
    >
      <Box component="header">
        <CardMedia
          component="img"
          height="180"
          image={imageUrl}
          alt={`Image for the blog post titled ${title}`}
          loading="lazy"
          sx={{
            objectFit: "cover",
            borderRadius: "4px 4px 0 0",
            width: "100%",
          }}
        />
      </Box>

      <CardContent sx={{ padding: "16px", flexGrow: 1 }}>
        <Typography
          component="h2"
          fontFamily="Raleway"
          color={theme.palette.primary.main}
          sx={{
            fontWeight: 600,
            fontSize: "1.1rem",
            textAlign: "center",
            marginBottom: "6px",
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="subtitle2"
          color="textSecondary"
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontSize: "0.875rem",
          }}
        >
          By <span style={{ fontWeight: 500 }}>{author}</span> on{" "}
          {new Date(date).toLocaleDateString()}
        </Typography>

        <Typography
          variant="body2"
          color={theme.palette.text.primary}
          fontFamily="Raleway"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 4, 
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardPost;
