import {
  Box,
  GlobalStyles,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { gradients } from "../../theme/gradients";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CardPortfolio from "../../components/Cards/CardPortfolio";
import { strings } from "../../core/utils/strings";
import { Navigation } from "swiper/modules";
import { mockPortfolio } from "../../core/utils/contants";
import MenuTabs from "../../components/Tabs/MenuTabs";

const PortfolioSection = () => {
  const theme = useTheme();

  // eslint-disable-next-line no-unused-vars
  const [activeTitle, setActiveTitle] = useState(
    strings.PORTFOLIO[mockPortfolio[0].title]
  );
  const [selectedChip, setSelectedChip] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const newTitle = strings.PORTFOLIO[mockPortfolio[activeIndex].title];
    setActiveTitle(newTitle);
    setSelectedChip(activeIndex);
  };

  const handleChipChange = (chipIndex) => {
    setSelectedChip(chipIndex);
  };

  const typeImage = matches ? "desktop" : "mobile";

  return (
    <>
      <GlobalStyles
        styles={{
          ".swiper-button-prev, .swiper-button-next": {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder",
            "&:hover": {
              color: theme.palette.secondary.main,
            },
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10,
          },
          ".swiper-button-prev": {
            left: "15vw",
          },
          ".swiper-button-next": {
            right: "15vw",
          },
        }}
      />

      <Grid
        container
        sx={{
          background: gradients.generalGradient,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <Box>
          <Typography
            component="h1"
            fontFamily={"Raleway"}
            gutterBottom
            sx={{
              pt: { xs: 8, sm: 4, md: 8 },
              color: theme.palette.primary.white,
              fontWeight: 700,
              fontSize: "2rem",
              marginTop: { xs: 0, sm: 0 },
            }}
          >
            {strings.PORTFOLIO_TITLE}
          </Typography>

          <Box
            sx={{
              width: "20%",
              height: "8px",
              backgroundColor: theme.palette.primary.main,
            }}
          />
        </Box>

        <Grid>
          <MenuTabs
            matches={typeImage}
            title={mockPortfolio}
            swiperRef={swiperRef}
            onChipChange={handleChipChange}
            selectedChip={selectedChip}
          >
            <Swiper
              freeMode={true}
              onSlideChange={handleSlideChange}
              slidesPerView={"auto"}
              scrollbar={{ draggable: false }}
              navigation={true}
              modules={[Navigation]}
              pagination={{
                clickable: true,
              }}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
              {mockPortfolio.map((portfolio, index) => (
                <SwiperSlide
                  key={index}
                  sx={{
                    zIndex: 1,
                    width: { xs: "100%", md: "80%" },
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <CardPortfolio image={portfolio.image[typeImage]} title={portfolio.title}/>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </MenuTabs>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioSection;
