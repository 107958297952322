import React from 'react';
import OurTeam from '../pages/managed-teams/OurTeam';
import BusinessPartner from "../pages/businesspartner/BusinessPartner";
import Blog from "../pages/blog/Blog";


const Home = React.lazy(() => import('../pages/Home'));

const router = [
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/managed-teams",
        element: <OurTeam />,
  },
  {
    path: "/businesspartner",
    element: <BusinessPartner />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
];

export default router;



