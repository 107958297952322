import {
  Box,
  Grid,
  Container,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { strings } from "../../../core/utils/strings";
import CardOurLeaders from "../../../components/Cards/CardOurLeaders";
import { gradients } from "../../../theme/gradients";
import { useNavigate, useLocation } from "react-router-dom";




const OurTeamSectionMobile = ({ leaderIds = [] }) => {
  // Filtrar los ids que no existen en strings.OURLEADERS
  const slideConfigs = Array.isArray(leaderIds)
    ? leaderIds
        .filter((id) => strings.OURLEADERS[id])
        .map((id) => strings.OURLEADERS[id])
    : [];

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

   const handleButtonTeam = () => {
    navigate("/managed-teams");
  };

  const handleButtonSales = () => {
    navigate("/businessPartner");
  };



  return (
    <Container
      sx={{
        mt: { xs: 12, sm: 10 },
        height: { sm: "100vh", xs: "100vh" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        background: gradients.generalGradient,
      }}
    >
      <Typography
        component="h2"
        fontFamily={"Raleway"}
        gutterBottom
        sx={{
          pt: 4,
          color: theme.palette.primary.white,
          fontWeight: 700,
          fontSize:"2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {strings.OURLEADERS_TITLE}
      </Typography>

      <Box
          sx={{
            width: "20%",
            height: "8px",
            backgroundColor: theme.palette.primary.main,
            marginBottom: { xs: '3rem', sm: '5rem' },
          }}
        />

      {slideConfigs.map((leaders, index) => (
        <Grid item xs={12} key={index}>
          <CardOurLeaders
            name={leaders.NAME}
            rol={leaders.ROL}
            description={leaders.DESCRIPTION}
            avatar={leaders.AVATAR}
            avatarAlignment={"left"}
          />
        </Grid>
      ))}
      {location.pathname !== "/managed-teams" && (
        <Grid
          container
          direction="column"
          spacing={0}
          alignItems="center"
          p={1}
        >
          <Grid item xs={12}>
            <ActionButton
              theme={theme}
              sx={{
                mt: 2,
                backgroundColor: theme.palette.primary.main,
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
              onClick={handleButtonSales}
              label={strings.OURLEADERS_BUTTON_SALES}
            />
          </Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <ActionButton
              theme={theme}
              sx={{
                mt: 2,
                backgroundColor: theme.palette.primary.main,
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
              onClick={handleButtonTeam}
              label={strings.OURLEADERS_BUTTON_CEO}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const ActionButton = ({ theme, label, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      mt: 5,
      p: 1,
      backgroundColor: theme.palette.primary.main,
      "&:hover": { backgroundColor: theme.palette.action.hover },
      borderRadius: "20px",
       color: "white !important",
      minWidth: { xs: "40vw", md: "15vw" },
    }}
  >
    {label}
  </Button>
);


export default OurTeamSectionMobile;
