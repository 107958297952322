import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import CardPost from "../../components/Cards/CardPost";
import { gradients } from "../../theme/gradients";

const BlogSection = () => {
  const theme = useTheme();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const options = {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer pat-na1-e74f04e7-9a3b-48ed-b70e-b6f47e00a26d',

          }
        };
  
        const response = await fetch('https://api.hubapi.com/cms/v3/blogs/posts', options);
        const data = await response.json();
  
        if (data && data.results) {
          const blogData = data.results.map((post) => ({
            title: post.name,
            author: post.authorName || "Desconocido",
            date: post.publishDate,
            content: post.postBody,
            imageUrl: post.featuredImage,
            articleUrl: post.url,
            metaDescription: post.metaDescription
          }));
  
          setPosts(blogData);
        } else {
          console.error("No se encontraron publicaciones.");
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setLoading(false);
      }
    };
  
    fetchBlogPosts();
  }, []);
  
  if (loading) {
    return <Typography>Cargando publicaciones...</Typography>;
  }
  


return (

    <Grid
      container
         component="section"
        aria-labelledby="blog-section-title"
      sx={{
        
        width:"100vw",
        height:"100vh",
        background: gradients.generalGradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        pt:10
      }}
    >
      <Typography
        id="blog-section-title"
        variant="h1"
        component="h1"
        fontFamily="Raleway"
        gutterBottom
        sx={{
          color: theme.palette.primary.white,
          fontWeight: 700,
          textAlign: "center",
          fontSize: "4rem"
        }}
      >
        Blog
      </Typography>

      <Box
        sx={{
          width: "50px",
          height: "8px",
          backgroundColor: theme.palette.primary.main,
          marginBottom: "2rem",
        }}
      />

      <Grid
        container
        spacing={4}
        justifyContent="center"
        sx={{ maxWidth: "1200px", padding: "0 2rem" }}
      >
  {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.articleUrl}>
              <CardPost
                title={post.title}
                author={post.author}
                date={new Date(post.date).toLocaleDateString()}
                content={post.content}
                imageUrl={post.imageUrl}
                articleUrl={post.articleUrl}
                metaDescription={post.metaDescription}
              />
            </Grid>
          ))} :


      </Grid>
    </Grid>
);
};

export default BlogSection;


