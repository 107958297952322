import React from "react";
import {
  Box,
  Grid,
  GlobalStyles,
  Typography,
  useTheme,
} from "@mui/material";
import { strings } from "../../../core/utils/strings";
import { gradients } from "../../../theme/gradients";
import { Navigation, Autoplay } from "swiper/modules";
import CardTestimonials from "../../../components/Cards/CardTestimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { mockTestimonials } from "../../../core/utils/contants";

import "./styles.css";

const TestimonialsSeccion = () => {
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          ".swiper-button-prev, .swiper-button-next": {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder",
            "&:hover": {
              color: theme.palette.secondary.main,
            },
          },
          ".swiper-button-prev": {
            left: "20%"
          },
          ".swiper-button-next": {
            right: "20%" 
          },
        }}
      />

      <Grid
        container
        sx={{
          background: gradients.generalGradient,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          height: "100vh",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            border: "2px",
            borderRadius: "20px",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: { xs: 2, md: 15},
          }}
        >
          <Box>
            <Typography
              variant="h2"
              component="h2"
              fontFamily={"Raleway"}
              gutterBottom
              sx={{
                pb: 2,
                color: theme.palette.primary.white,
                fontWeight: 700,
              }}
            >
              {strings.TESTIMONIALS_TITLE}
            </Typography>
            <Box
              sx={{
                width: "20%",
                height: "8px",
                backgroundColor: theme.palette.primary.main,
                marginBottom: "3rem",
              }}
            />
          </Box>
        </Grid>

        <Grid
          container
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 35,
          }}
        >
          <Swiper
            freeMode={true}
            slidesPerView={"auto"}
            scrollbar={{ draggable: false }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {mockTestimonials.map((testimonial, index) => (
              <SwiperSlide key={index} sx={{ zIndex: 1 }}>
                <CardTestimonials
                  name={testimonial.NAME}
                  description={testimonial.DESCRIPTION}
                  avatar={testimonial.AVATAR}
                  avatarAlignment={"left"}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </>
  );
};

export default TestimonialsSeccion;
