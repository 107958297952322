import React from "react";
import { Box } from "@mui/material";
import InitialSection from "../../../components/Initial";
import { useActiveSection } from "../../../context/ActiveSection.context";

import { configSlideHero } from "../../../core/utils/contants";

const HeroSectionMobile = () => {
  const { activeSection } = useActiveSection();
  const slideConfig = configSlideHero[activeSection] || configSlideHero[0];

  return (
    <Box
      component="section"
      id={`home-${activeSection}`}
      sx={{ height: "100vh" }}
    >
      <InitialSection
        title={slideConfig.title}
        buttonConfig={slideConfig.buttonConfig}
        background={slideConfig.background.mobile}
        description={slideConfig.description}
      />
    </Box>
  );
};

export default HeroSectionMobile;
