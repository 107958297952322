import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import InitialSection from "../../../components/Initial";
import { configSlideHero } from "../../../core/utils/contants";
import { styled } from '@mui/system';


const Slide = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  maxWidth: '100vw',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  color: theme.palette.primary.main,
  transition: 'opacity 4s  ease-in-out', 
  "@media (max-width: 768px)": {
    padding: '0.5rem',
  },
}));

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0.5); 
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === configSlideHero.length - 1 ? 0 : prevIndex + 1
        );
        setOpacity(1); 
      }, 3000); 
    }, 6500); 

    return () => clearInterval(interval);
  }, []);

  const renderSlide = (config) => (
    <Slide
      sx={{
        backgroundImage: config.background,
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: opacity,
      }}
    >
      <InitialSection
        title={config.title}
        buttonConfig={config.buttonConfig}
        background={config.background.desktop}
        description={config.description}
      />
    </Slide>
  );

  return (
    <Box component="section" id="Home">
      {renderSlide(configSlideHero[currentIndex])}
    </Box>
  );
};

export default HeroSection;
