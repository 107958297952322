import React from "react";
import {
  Box,
  Grid,
  GlobalStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { strings } from "../../../core/utils/strings";
import { gradients } from "../../../theme/gradients";
import { Navigation, Autoplay } from "swiper/modules";
import CardTestimonials from "../../../components/Cards/CardTestimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { mockTestimonials } from "../../../core/utils/contants";

import "./styles.css";

const TestimonialsSeccionMobile = () => {
  const theme = useTheme();
      const matches = useMediaQuery(theme.breakpoints.up("md"));


  return (
    <>
      <GlobalStyles
        styles={{
          ".swiper-button-prev, .swiper-button-next": {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder",
            "&:hover": {
              color: theme.palette.secondary.main,
            },
          },
          ".swiper-button-prev": {
            left: "5%",
          },
          ".swiper-button-next": {
            right: "5%",
          },

        }}
      />

      <Grid
        container
        sx={{
          background: gradients.generalGradient,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matches? "column": "row",
          height: "100vh",
          width: "100%",
          padding: "2rem",
        }}
      >
        <Grid >
          <Typography
            variant="h4"
            component="h2"
            fontFamily={"Raleway"}
            gutterBottom
            sx={{
              pt:matches ? 1: 5,
              color: theme.palette.primary.white,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {strings.TESTIMONIALS_TITLE}
          </Typography>
          <Box
            sx={{
              width: "50%",
              height: "4px",
              backgroundColor: theme.palette.primary.main,
              marginBottom: "5rem",
              margin: "0 auto",
            }}
          />
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Swiper
            freeMode={true}
            slidesPerView={1}
            scrollbar={{ draggable: false }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {mockTestimonials.map((testimonial, index) => (
              <SwiperSlide key={index} sx={{ zIndex: 1 }}>
                <CardTestimonials
                  name={testimonial.NAME}
                  description={testimonial.DESCRIPTION}
                  avatar={testimonial.AVATAR}
                  avatarAlignment={"left"}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </>
  );
};

export default TestimonialsSeccionMobile;
